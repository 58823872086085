import { useEffect, useState } from 'react'
import axios from 'axios'
import 'swiper/css'
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Sidecircleshape from '../../img/service/Side Shape Circle.svg'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'

export default function Service() {
    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
    const [ourservices, setOurservices] = useState({ data:[
        {
            id: 1,
            image: 'analysis.gif',
            title: 'Шалгалт, шинжилгээ',
            description:
                'Эмзэг байдлын (Vulnerability), Нэвтрэлтийн (Penetration test), Халдлагын дараах (Forensic analysis)  зэрэг шалгалт, шинжилгээ.',
            link: 'analysis',
            createdAt: '2022-04-23T20:09:28.000Z',
            updatedAt: '2022-04-23T20:09:28.000Z',
        },
    ]})

    useEffect(() => {
        axios({
            url: '/api/services?page=0&size=20&searchPattern',
            headers: { Authorization: 'Bearer jdsajdkjlas' },
            method: 'GET',
        }).then((response) => {
            setOurservices(response.data)
        })
    }, [])

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div
            id="service"
            data-aos="fade-left"
            className="flex justify-center bg-colorbg2 xs:py-10 w-full py-20 overflow-hidden"
        >
            <img
                alt="circle"
                className="absolute right-0 mt-64 xs:hidden"
                src={Sidecircleshape}
            ></img>
            <div className=" flex flex-col w-4/5 md:w-full lg:w-4/5 2xl:max-w-8xl xs:px-5 xs:w-full xl:w-4/5">
                <div className="text-center cursor-default">
                    <h1 className="text-h1red text-4xl font-bold py-8 xs:text-3xl">
                        Бид юу хийдэг вэ?
                    </h1>
                    <p className="text-dark text-base px-32 xs:px-0 sm:px-0 text-center">
                        Чек Пойнт ХХК нь 2021 онд үүсгэн байгуулагдсан
                        мэдээллийн аюулгүй байдлын аудитын компани
                        юм.
                    </p>
                </div>
                <div className="flex py-5 xs:px-0 xs:space-y-5 sm:px-0 sm:space-y-5 md:px-0 md:space-y-5 2xl:px-24 lg:px-0">
                    <Swiper
                        breakpoints={{
                            // when window width is >= 640px
                            300: {
                                spaceBetween: 40,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            640: {
                                spaceBetween: 1,
                                slidesPerView: 1,
                            },
                            768: {
                                spaceBetween: 1,
                                slidesPerView: 1,
                            },
                            1023: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        // navigation
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                    >
                        {ourservices.data &&
                            ourservices.data.map((service, idx) => (
                                <SwiperSlide
                                    key={service.id}
                                    className="flex justify-center py-10 px-5 xs:px-5 xs:space-y-5 sm:space-y-5 md:space-y-5 lg:px-0 2xl:px-0"
                                >
                                    <div className="flex flex-col bg-white w-96 shadow-xl rounded-lg p-3 ">
                                        <div className="">
                                            <div className="flex justify-center">
                                                <img
                                                    alt={idx}
                                                    className="w-4/5 "
                                                    src={`/images/service/${service.image}`}
                                                ></img>
                                            </div>
                                            <div className="text-justify cursor-default">
                                                <h3 className="text-h1red text-xl font-bold">
                                                    {service.title}
                                                </h3>
                                                <p className="text-dark my-2 text-base leading-7 pb-5 ">
                                                    {service.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="absolute bottom-0 justify-items-end h-24">
                                            <div className="relative ">
                                                <Link
                                                    to={service && service.link}
                                                    className="cursor-default text-center p-2 rounded-sm w-32 h-10 bottom-0 bg-buttoncolor text-buttontextcolor text-base bg-gradient-to-b from-colorbtn1 to-colorbtn2"
                                                >
                                                    Дэлгэрэнгүй
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                            ...
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
