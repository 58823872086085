import React from 'react'

import Serviceinfo from '../../pages/audit/serviceinfo'
import Otherservice from '../../components/otherservice'
import News from '../../pages/home/news'
import Getinfo from '../../components/getinfo'


export default function audit() {
  return (
   <> 
            <Serviceinfo />
            <Getinfo model={"audit"}/>
            <Otherservice />
            <News/>
      
   </>
  )
}
