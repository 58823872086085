import React, { useEffect, useRef } from 'react'

import Hero from './hero'
import About from './about'
import Ourteam from './ourteam'
import Service from './service'
import Feedback from './feedback'
import News from './news'
import Clients from '../../components/clients'

export default function Home(props) {
    // const scrollToSection = (elementRef, type) => {
    //     let offSet = elementRef.current.offsetTop + (type === 'news' ? 200 : 0)
    //     window.scrollTo({
    //         top: offSet,
    //         behavior: 'smooth',
    //     })
    // }

    // useEffect(()=> {
    //   switch(window.location.search) {
    //     case '?aboutus':
    //       scrollToSection(about, "about")
    //       break;
    //     case '?service':
    //       scrollToSection(service, "service")
    //       break;
    //     case '?feedback':
    //       scrollToSection(feedback, "feedback")
    //       break;
    //     case '?news':
    //       scrollToSection(news, "news")
    //       break;
    //   }

    // }, [])

    return (
        <>
            <div className="overflow-x-hidden">
                <Hero />
                <About />
                <Ourteam />
                <Service />
                <Clients />
                <Feedback />
                <News />
            </div>
        </>
    )
}
