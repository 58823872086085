import logo from '../img/logo.svg'
import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'

export default function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false)

    const [color, setColor] = useState(false)

    useEffect(() => {
        if (window.scrollY >= 90) {
            setColor(true)
        } else {
            setColor(false)
        }
    }, [window.scrollY])

    return (
        <div
            className={
                color
                    ? 'flex fixed justify-center bg-colorbg2 h-24 w-full shadow-md top-0 z-50 xs:h-20 transition-all duration-300'
                    : 'flex fixed justify-center bg-colorbg1 h-24 w-full top-0 z-50 xs:h-20 transition-all duration-300'
            }
        >
            <div className="flex w-4/5 my-8 top-0">
                <div className="w-1/5 flex items-center xs:w-32">
                    <HashLink to="/#home">
                        <img className="w-32" src={logo} alt="logo"></img>
                    </HashLink>
                </div>

                <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
                    <div
                        className="absolute top-0 right-0 px-8 py-8"
                        onClick={() => setIsNavOpen(false)}
                    >
                        <svg
                            className="h-8 w-8 text-gray-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                    <ul className="flex flex-col items-center justify-between min-h-[250px]">
                        <li className="border-b border-gray-400 my-8 uppercase">
                            <HashLink smooth to="/#about"
                                onClick={() => setIsNavOpen(false)}>
                                Бидний тухай
                            </HashLink>
                        </li>
                        <li className="border-b border-gray-400 my-8 uppercase">
                            <HashLink smooth to="/#service"
                                onClick={() => setIsNavOpen(false)}>
                                Үйлчилгээ
                            </HashLink>
                        </li>
                        <li className="border-b border-gray-400 my-8 uppercase">
                            <HashLink smooth to="/#clients"
                                onClick={() => setIsNavOpen(false)}>
                                Харилцагч
                            </HashLink>
                        </li>
                        <li className="border-b border-gray-400 my-8 uppercase">
                            <HashLink smooth to="/#news"
                                onClick={() => setIsNavOpen(false)}>
                                Мэдээлэл
                            </HashLink>
                        </li>
                        <li className="border-b border-gray-400 my-8 uppercase">
                        <Link
                                to="/contact"
                                onClick={() => setIsNavOpen(false)}
                                // onClick={() => navigate('/analysis')}
                                className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                            >
                                Холбоо барих
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="w-4/5 flex items-center justify-end">
                    <div
                        className="HAMBURGER-ICON space-y-2 lg:hidden xl:hidden 2xl:hidden 3xl:hidden"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                    </div>
                    <ul className="flex justify-end items-center space-x-10 sm:hidden xs:hidden md:hidden">
                        <li className="hover:text-h1red transition-all duration-300">
                            <HashLink smooth to="/#about">
                                Бидний тухай
                            </HashLink>
                        </li>
                        <li className="hover:text-h1red transition-all duration-300">
                            <HashLink smooth to="/#service">
                                Үйлчилгээ
                            </HashLink>
                        </li>
                        <li className="hover:text-h1red transition-all duration-300">
                            <HashLink smooth to="/#clients">
                                Харилцагч
                            </HashLink>
                        </li>
                        <li className="hover:text-h1red transition-all duration-300">
                            <HashLink smooth to="/#news">
                                Мэдээлэл
                            </HashLink>
                        </li>
                        <li className="hover:text-h1red transition-all duration-300">
                            {/* <div className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"> */}
                            <Link
                                to="/contact"
                                // onClick={() => navigate('/analysis')}
                                className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                            >
                                Холбоо барих
                            </Link>
                            {/* </div> */}
                        </li>
                    </ul>
                </div>
                <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
            </div>
        </div>
    )
}
