import React from 'react'
import Anotherservice from '../../pages/other-service/anotherservice'
import Getinfo from '../../components/getinfo'
import Otherservice from '../../components/otherservice'
import News from '../../pages/home/news'
export default function index() {
  return (

      <>
       <Anotherservice />
       <Getinfo model={"otherservice"}/>
       <Otherservice />
       <News/>
     </>
  )
}
