import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Line from '../../img/serviceinfo/line.svg'
import Audit from '../../img/risk.gif'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import '../../App.css'
import Typography from '@mui/material/Typography'

const steps = [
    {
        id: 1,
        label: 'Цар хүрээг тодорхойлох',
        description1: '•Байгууллагын МТ-ийн өмч хөрөнгийгтодорхойлох',
    },
    {
        id: 2,
        label: 'Эрсдэлийн үнэлгээ хийх',
        description1: '•Аюул заналаа тодорхойлох',
        description2: '•Нөлөөллөө тодорхойлох',
        description3: '•Магадлалыг тооцоолох',
        description4: '•Эрсдэлийг үнэлэх',
    },
    {
        id: 3,
        label: 'Эрсдэлийг удирдах',
        description1: '•Цаашид эрсдэлүүдийг удирдах аргачлалаар хангана',
    },
]

export default function Serviceinfo() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-colorbg1 z-0  2xl:mt-0 cursor-default">
                <div className=" flex flex-col justify-center  w-4/5 text-center text-5xl px-40 z-30 font-medium py-44 xs:w-full xs:px-0 xs:py-28 xs:mt-10 xs:text-3xl sm:w-full sm:px-0 sm:mt-12 md:w-full md:mt-8 md:px-0 lg:px-0 lg:mt-12 xl:mt-10 2xl:mt-20">
                    <h1 className="text-dark">Байгууллагын мэдээллийн</h1>
                    <h1 className="text-dark">технологийн</h1>
                    <h1 className="text-h1red">Эрсдэлийн үнэлгээ</h1>
                </div>
                <div className=" h-4 bg-gradient-to-r from-colorbtn1 to-colorbtn2 w-full"></div>
            </div>
            <div className="flex justify-center mt-28 xs:mt-5 xs:mb-0 sm:mb-0 lg:mt-10 xl:mt-10 2xl:mt-10 cursor-default">
                <div className="flex space-x-24 w-[80%] xs:w-full xs:flex-col-reverse xs:space-x-0 sm:w-full sm:flex-col-reverse sm:space-x-0 md:w-full md:flex-col-reverse md:space-x-0 2xl:max-w-9xl">
                    <div className="w-[50%] md:w-full sm:w-full xs:w-full py-20 xs:px-5 sm:px-5 md:px-5 xs:py-0">
                        <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl xs:text-center">
                            Эрсдэлийн үнэлгээ
                        </h1>
                        <img alt="pic" className="xs:hidden" src={Line}></img>
                        <p className="text-dark text-lg mt-7 text-justify">
                            {' '}
                            Байгууллагын эрсдэлийн удирдлагын үр дүнтэй
                            хөтөлбөрийг бий болгох нь нарийн төвөгтэй үйл
                            ажиллагаа боловч хэрэгжүүлж чадсан тохиолдолд
                            байгууллагад учирч болох аюул заналуудаас сэргийлэх
                            хамгийн үр дүнтэй арга хэрэгсэл болдог. Ийм
                            хөтөлбөрийн нэг гол хэсэг нь мэдээллийн технологийн
                            эрсдэлийн удирдлага юм.<br></br>
                            <br></br>Чек Пойнт ХХК нь тантай хамтран ажиллаж,
                            байгууллагын мэдээллийн технологийн эрсдэлийг
                            тодорхойлох, үнэлэх, удирдах, хянах, тооцоолох
                            боломжийг танд олгоно. Энэ нь эрсдэлийг бууруулах,
                            бизнесийн үр ашигтай шийдвэр гаргах гол үндэс
                            болдог.
                        </p>
                    </div>
                    <div className="w-[50%] md:w-full sm:w-full xs:w-full flex justify-center items-center">
                        <img alt="pic" src={Audit}></img>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center mb-44 items-center px-8 xs:px-0 sm:px-0 cursor-default">
                <div className="w-4/5 xs:text-center xs:pt-5 2xl:max-w-8xl">
                    <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl ">
                        Үйл ажиллагаа
                    </h1>
                    <img alt="pic" className="xs:hidden" src={Line}></img>
                    <p className="opacity-60">
                        Доорх үйл ажиллагааг хийж гүйцэтгэх зааварчилгаа, арга
                        хэрэгслээр захиалагчийг хангана.
                    </p>
                </div>
                <div className="w-4/5 mt-12 flex justify-center xs:hidden sm:hidden 2xl:max-w-9xl">
                    <div className="">
                        <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel activeStep={4}>
                                {steps &&
                                    steps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel>{step.label}</StepLabel>
                                            <Typography mt={1} ml={12}>
                                                {step.description1}
                                            </Typography>
                                            <Typography mt={1} ml={12}>
                                                {step.description2}
                                            </Typography>
                                            <Typography mt={1} ml={12}>
                                                {step.description3}
                                            </Typography>
                                            <Typography mt={1} ml={12}>
                                                {step.description4}
                                            </Typography>
                                        </Step>
                                    ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
                <div className="w-full mt-12 flex justify-center md:hidden lg:hidden xl:hidden 2xl:hidden">
                    <div className=" px-0  text-center">
                        <Box sx={{ width: '100%' }}>
                            <Stepper
                                alternativeLabel
                                activeStep={4}
                                orientation="vertical"
                            >
                                {steps &&
                                    steps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel>{step.label}</StepLabel>
                                            <Typography mt={1} mx={8}>
                                                {step.description1}
                                            </Typography>
                                            <Typography mt={1} mx={8}>
                                                {step.description2}
                                            </Typography>
                                            <Typography mt={1} mx={8}>
                                                {step.description3}
                                            </Typography>
                                        </Step>
                                    ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}
