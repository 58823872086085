import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Line from '../../img/serviceinfo/line.svg'
import Redround from '../../img/serviceinfo/redround.svg'
import Audit from '../../img/audit.gif'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import '../../App.css'

const steps = [
    {
        id: 1,
        label: 'Хамрах хүрээ тодорхойлох',
        description1: '•Аудитын хэрэгцээ, шаардлага тодорхойлох',
        description2: '•Аудитын зорилго тодорхойлох',
        description3: '•Хамрах хүрээг тодорхойлох',
    },
    {
        id: 2,
        label: 'Бэлтгэл',
        description1: '•Дата мэдээлэл цуглуулах',
        description2: '•Бичиг баримт цуглуулах',
    },
    {
        id: 3,
        label: 'Шинжилгээ хийх',
        description1:
            '•Бэлтгэл ажлын хүрээнд авсан мэдээллүүд дээр тулгуурлан зохих стандартуудын дагуу аудитын үйл ажиллагааг явуулж үл нийцлүүдийг илрүүлнэ',
        description2: '•Олдсон үл нийцлүүдэд дүгнэлт хийж зөвлөмж гаргана',
    },
    {
        id: 4,
        label: 'Тайлан мэдээ гаргах',
        description1: '•Урьдчилсан тайлан илгээж захиалагчтай санал солилцох',
        description2:
            '•Захиалагчид зориулсан эцсийн тайланг бэлдэж, танилцуулах',
    },
]

export default function Serviceinfo(props) {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-colorbg1 z-0  2xl:mt-0 cursor-default">
                <div className=" flex flex-col justify-center  w-4/5 text-center text-5xl px-40 z-30 font-medium py-44 xs:w-full xs:px-0 xs:py-28 xs:mt-10 xs:text-3xl sm:w-full sm:px-0 sm:mt-12 md:w-full md:mt-8 md:px-0 lg:px-0 lg:mt-12 xl:mt-10 2xl:mt-20">
                    <h1 className="text-dark">Мэдээллийн технологи</h1>
                    <h1 className="text-dark">Мэдээллийн аюулгүй байдлын</h1>
                    <h1 className="text-h1red">Аудитын үйлчилгээ</h1>
                </div>
                <div className=" h-4 bg-gradient-to-r from-colorbtn1 to-colorbtn2 w-full"></div>
            </div>
            <div className="flex justify-center mt-28 xs:mt-5 xs:mb-0 sm:mb-0 lg:mt-10 xl:mt-10 2xl:mt-10 cursor-default">
                <div className="flex space-x-24 w-[80%] xs:w-full xs:flex-col-reverse xs:space-x-0 sm:w-full sm:flex-col-reverse sm:space-x-0 md:w-full md:flex-col-reverse md:space-x-0 2xl:max-w-9xl">
                    <div className="w-[50%] md:w-full sm:w-full xs:w-full py-20 xs:px-5 sm:px-5 md:px-5 xs:py-0">
                        <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl xs:text-center">
                            Аудитын үйлчилгээ
                        </h1>
                        <img alt="pic" className="xs:hidden" src={Line}></img>
                        <p className="text-dark text-lg mt-7 text-justify">
                            {' '}
                            Мэдээллийн аюулгүй байдалдаа анхаарал бага хандуулах
                            нь мэдээлэл, мэдээллийн системийн бүрэн бүтэн болон
                            бэлэн байдал, нууцлалд муугаар нөлөөлдөг. Ийм
                            эрсдэлийг бууруулдаг гол арга хэмжээ нь
                            байгууллагадаа мэдээллийн аюулгүй байдлын аудит
                            хийлгэх юм. <br />
                            <br />
                            Чек Пойнт ХХК нь захиалагчийн хүсэлтээр байгууллагын
                            үйл ажиллагаа, түүний мэдээллийн технологийн орчинд
                            ISO 27001:2013 стандарт болон COBIT 5, COBIT 2019
                            тогтолцоонуудын дагуу эрсдэлд суурилсан аудит хийн
                            дүгнэлт, зөвлөмж гарган, тайлагнах үйлчилгээ
                            үзүүлдэг. <br />
                            <br />
                            Чек Пойнт ХХК нь алхам тутамдаа тантай хамт байж,
                            танай үйл ажиллагаа нь зохицуулалт, олон улсын
                            стандартын шаардлагууд болон мэдээллийн аюулгүй
                            байдлын шилдэг туршлагуудад нийцэж байхын баталгаа
                            болж чадна.
                        </p>
                    </div>
                    <div className="flex justify-center items-center w-[50%] md:w-full sm:w-full xs:w-full  ">
                        <img alt="pic" src={Audit}></img>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center px-8 xs:px-0 sm:px-0 cursor-default">
                <div className="w-4/5 xs:text-center xs:pt-5 2xl:max-w-8xl">
                    <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl ">
                        Үйл ажиллагаа
                    </h1>
                    <img alt="pic" className="xs:hidden" src={Line}></img>
                </div>
                <div className="w-4/5 mt-12 flex justify-center xs:hidden sm:hidden  md:hidden 2xl:max-w-9xl">
                    <div className="">
                        <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel activeStep={4}>
                                {steps &&
                                    steps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel>{step.label}</StepLabel>
                                            <Typography mt={1} ml={8}>
                                                {step.description1}
                                            </Typography>
                                            <Typography mt={1} ml={8}>
                                                {step.description2}
                                            </Typography>
                                            <Typography mt={1} ml={8}>
                                                {step.description3}
                                            </Typography>
                                        </Step>
                                    ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
                <div className="w-full mt-12 flex justify-center lg:hidden xl:hidden 2xl:hidden cursor-default">
                    <div className=" px-0  text-center">
                        <Box sx={{ width: '100%' }}>
                            <Stepper
                                alternativeLabel
                                activeStep={4}
                                orientation="vertical"
                            >
                                {steps &&
                                    steps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel>{step.label}</StepLabel>
                                            <Typography mt={1} mx={8}>
                                                {step.description1}
                                            </Typography>
                                            <Typography mt={1} mx={8}>
                                                {step.description2}
                                            </Typography>
                                            <Typography mt={1} mx={8}>
                                                {step.description3}
                                            </Typography>
                                        </Step>
                                    ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center px-8 pt-12 pb-36">
                <div className="w-4/5 xs:w-full text-justify 2xl:max-w-9xl">
                    <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl xs:text-center">
                        Манайхаар аудит хийлгэсний давуу тал{' '}
                    </h1>
                    <img alt="pic" className="xs:hidden" src={Line}></img>
                    <p className="opacity-60">
                        Үйлчилгээний үнийн дүнгээс хамааруулан дараах
                        үйлчилгээнүүдийг урамшуулал болгон нэмэлтээр үзүүлж
                        болно.{' '}
                    </p>
                </div>
                <div className="flex text-center w-4/5 my-12 xs:flex-col xs:space-y-8 xs:w-full xs:items-center 2xl:max-w-9xl">
                    <div className="w-1/3 xs:w-full ">
                        <div className="flex justify-center pb-6">
                            <img alt="pic" src={Redround}></img>
                        </div>
                        <div className="">
                            <h2 className="text-dark font-bold text-2xl">
                                Сургалт
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 text-justify">
                                Аудитын үр дүнд тулгуурласан байгууллагын МАБ-ын
                                нэг удаагын мэдлэг олгох сургалт хийх
                            </p>
                        </div>
                    </div>
                    <div className="w-1/3 xs:w-full">
                        <div className="flex justify-center pb-6">
                            <img alt="pic" src={Redround}></img>
                        </div>
                        <div className="">
                            <h2 className="text-dark font-bold text-2xl">
                                Эмзэг байдлын шалгалт
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 text-justify">
                                Аудитын хамрах хүрээнд эмзэг байдлын шалгалт
                                хийгдсэн системүүдэд аудитын мөрөөр нэг удаагын
                                эмзэг байдлын шалгалт хийх
                            </p>
                        </div>
                    </div>
                    <div className="w-1/3 xs:w-full">
                        <div className="flex justify-center pb-6 ">
                            <img alt="pic" src={Redround}></img>
                        </div>
                        <div className="">
                            <h2 className="text-dark font-bold text-2xl">
                                Давтан аудит
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 text-justify">
                                {' '}
                                Эхний аудитын гэрээ байгуулснаас 24 сарын дотор
                                хамрах хүрээ өөрчлөгдөөгүй давтан аудитад үнийн
                                дүнгийн хөнгөлөлт үзүүлэх
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
