import React from 'react'
import Course from '../../pages/course/course'
import Getinfo from '../../components/getinfo'
import Otherservice from '../../components/otherservice'
import News from '../../pages/home/news'

export default function index() {
  return (
    <>
     <Course />
     <Getinfo model={"course"}/>
     <Otherservice />
     <News />

     </>
  )
}
