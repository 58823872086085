import NewsImg from '../img/news/news.jpg'
import Arrow from '../img/news/arrow.svg'
import Fblogo from '../img/news/Facebook.svg'
import Twlogo from '../img/news/Twitter.svg'
import Lnlogo from '../img/news/Linkedin.svg'

export default function NewsComp(props) {
    const data = props.item

    return (
        <div id="ln" className="">
            <div className="relative">
                <img
                    className="h-56 w-348 object-cover xs:hidden sm:hidden md:hidden"
                    src={data.image}
                    alt="news"
                ></img>
                <a href={`${data && data.link}`} target="_blank">
                    <img
                        className="absolute right-0 top-0 xs:static xs:w-16 sm:static sm:w-16 md:static md:w-20"
                        src={
                            data && data.type[0] === 't'
                                ? Twlogo
                                : data.type[0] === 'f'
                                ? Fblogo
                                : Lnlogo
                        }
                        alt="lnlogo"
                    ></img>
                </a>
            </div>
            <div className="w-348 mt-7 xs:hidden sm:hidden md:hidden ">
                <span className="text-dark opacity-60">
                    {data && data.date.split('T')[0]}
                </span>
                <h1 className="font-bold text-2xl text-justify ">
                    {data && data.title}
                </h1>
                <p className="text-dark opacity-60  my-2 text-justify ">
                    {data && data.description}
                </p>
                <div className="flex justify-between w-32 items-center">
                    <a
                        href={`${data && data.link}`}
                        target="_blank"
                        className="font-bold hover:p-1 hover:bg-buttoncolor hover:text-buttontextcolor hover:text-base hover:bg-gradient-to-b hover:from-colorbtn1 hover:to-colorbtn2 transition-all duration-300 hover:rounded-lg"
                    >
                        Read More
                    </a>
                    <img src={Arrow} alt="arrow"></img>
                </div>
            </div>
        </div>
    )
}
