import React, { useLayoutEffect, useState, useEffect } from 'react'
import {
    HashRouter as Router,
    Routes,
    Route,
    Navigate,
    BrowserRouter,
} from 'react-router-dom'
import './App.css'
import Navbar from './components/navbar'
import Footer from './components/footer'
import Home from './pages/home/index'
import Contact from './pages/contact/index'
import Course from './pages/course/index'
import Audit from './pages/audit/index'
import Risk from './pages/risk-assessment/index'
import Otherservice from './pages/other-service/index'
import Analysis from './pages/Analysis/index'
import Slider from './components/slider'
import history from './history'

const CustomRouter = ({ history, ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    })

    useLayoutEffect(() => history.listen(setState), [history])

    return (
        <BrowserRouter
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    )
}

export default function App() {
    return (
        <CustomRouter history={history} classname="cursor-pointer">
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/course" element={<Course />}></Route>
                <Route path="/audit" element={<Audit />}></Route>
                <Route path="/risk" element={<Risk />}></Route>
                <Route path="/otherservice" element={<Otherservice />}></Route>
                <Route path="/analysis" element={<Analysis />}></Route>
                <Route path="/slider" element={<Slider />}></Route>
            </Routes>
            <Footer />
        </CustomRouter>
    )
}
