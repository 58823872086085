import React from 'react'
import Contact from '../../pages/contact/contact'
import News from '../../pages/home/news'


export default function index() {
  return (
    <>
     <Contact />
     <News />
    </>
  )
}
