import React from 'react'

import Getinfo from '../../components/getinfo'
import Otherservice from '../../components/otherservice'
import News from '../../pages/home/news'
import Anotherservice from '../../pages/Analysis/anotherservice'
export default function index() {
  return (
    <>
        <Anotherservice />
        <Getinfo model={"analysis"}/>
        <Otherservice />
        <News/>
</>
  )
}
