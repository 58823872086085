import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Yellowdot from '../../img/dot.svg'
import img1 from '../../img/analysis/img1.svg'
import img2 from '../../img/analysis/img2.svg'
import img3 from '../../img/analysis/img3.svg'

export default function AnotherService() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <div className="flex justify-center items-center h-96 mt-10 bg-colorbg1 cursor-default ">
                <img
                    alt="pic"
                    className="-z-30  absolute top-72 left-20 bottom-0 xs:hidden sm:hidden"
                    src={Yellowdot}
                ></img>
                <div className="w-4/5">
                    <div className="flex justify-center items-center mt-8">
                        <div className="text-center">
                            <h1 className="text-5xl leading-tight">Шалгалт</h1>
                            <h1 className="text-5xl leading-tight text-h1red">
                                Шинжилгээ
                            </h1>
                        </div>
                    </div>
                    <div className=""></div>
                </div>
                <img
                    alt="pic"
                    className="-z-30  absolute top-24 right-20 xs:hidden sm:hidden"
                    src={Yellowdot}
                ></img>
            </div>
            <div className=" h-4 bg-gradient-to-r from-colorbtn1 to-colorbtn2 w-full "></div>

            <div className="flex flex-col justify-center pb-44 items-center mt-14 xs:mt-5 sm:mt-10 cursor-default">
                <div className="flex w-4/5 2xl:max-w-9xl xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
                    <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32">
                        <img alt="pic" src={img1}></img>
                    </div>
                    <div className=" w-2/4 xs:w-full sm:w-full">
                        <h1 className="text-3xl text-h1red xs:text-2xl">
                            Нэвтрэлтийн шалгалт
                        </h1>
                        <div className="mt-4">
                            <h2 className="text-xl text-h1red">
                                Penetration test :
                            </h2>
                            <p className="text-justify">
                                Кибер аюулгүй байдлын орчны аюул занал нь байнга
                                хувьсан өөрчлөгдөж байдаг тул байгууллагууд
                                өөрийн мэдээллийн системийн цоорхой, эмзэг
                                байдлыг илрүүлэх үйл ажиллагааг тогтмол хийж,
                                илэрсэн алдаа дутагдлыг шуурхай засварладаг байх
                                шаардлагатай.<br></br>
                                <br></br>Чек Пойнт ХХК нь салбартаа тэргүүлэгч
                                туршлага, хамгийн сүүлийн үеийн арга, хэрэгслийг
                                ашиглан нэвтрэлтийн шалгалтын үйлчилгээ үзүүлж
                                кибер аюулгүй байдлаа сахин хамгаалахад тань
                                тусална. Энэхүү шалгалтыг хийхийн тулд манай
                                кибер аюулгүй байдлын мэргэжилтнүүд танай
                                системд нэвтэрч аливаа цоорхой, эмзэг байдлыг
                                илрүүлэн засварлах аргачлалыг зөвлөмж болгоно.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex w-4/5 2xl:max-w-9xl py-20  xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
                    <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32">
                        <img alt="pic" src={img2}></img>
                    </div>
                    <div className=" w-2/4 xs:w-full sm:w-full">
                        <h1 className="text-3xl text-h1red xs:text-2xl">
                            Эмзэг байдлын шалгалт
                        </h1>
                        <div className="mt-4">
                            <h2 className="text-xl text-h1red">
                                Vulnerability assessment :
                            </h2>
                            <p className="text-justify">
                                Эмзэг байдлын үнэлгээг мэдээллийн технологийн
                                дэд бүтэц, мэдээллийн аюулгүй байдлынхаа сул
                                талыг тодорхойлох, тоон үзүүлэлтээр илэрхийлэх,
                                шинжлэхэд ашигладаг. <br></br>
                                <br></br>Чек Пойнт ХХК нь эмзэг байдлыг албан
                                ёсны шалгалтын хэрэгслүүд болон өөрсдийн
                                тодорхойлсон аргачлалыг ашиглаж, үнэн зөв үр
                                дүнг гаргаж, илэрсэн эмзэг байдлыг тодорхойлж,
                                засах зөвлөмжүүдийг хамт тайлагнадаг. Энэхүү
                                үнэлгээнд манай аюулгүй байдлын мэргэжилтнүүд
                                нарийвчилсан мэдээллийг сайтар судалж,
                                төхөөрөмжийн үндсэн тохиргоог ойлгож, мэдэгдэж
                                буй сул талуудын талаар мэдээллийг цуглуулдаг.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex w-4/5 2xl:max-w-9xl xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
                    <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32">
                        <img alt="pic" src={img3}></img>
                    </div>
                    <div className=" w-2/4 xs:w-full sm:w-full">
                        <h1 className="text-3xl text-h1red xs:text-2xl">
                            Халдлагын дараах шинжилгээ
                        </h1>
                        <div className="mt-4">
                            <h2 className="text-xl text-h1red">
                                Forensic analysis :
                            </h2>
                            <p className="text-justify">
                                Халдлагын дараах шинжилгээ нь байгууллагын
                                мэдээллийн аюулгүй байдлыг болон системийн
                                аюулгүй байдлыг зөрчсөн халдлага, түүний
                                шалтгаан, явц, үр дагаврыг илрүүлэх,
                                баримтжуулах, судлах, цаашид урьдчилан сэргийлэх
                                зорилготой үйл явц юм.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
