import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Contactimg1 from '../../img/contact.svg'
import Dot from '../../img/dot.svg'
import Location from '../../img/contact/location.png'
import Email from '../../img/contact/email.png'
import Phone from '../../img/contact/phone.png'
import axios from 'axios'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'

export default function Contact() {
    const initialValues = {
        name: '',
        phone: '',
        email: '',
        title: '',
        description: '',
    }
    const [formValues, setFormValues] = useState(initialValues)
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)

    const validate = (values) => {
        const errors = {}
        let isValid = true
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
        const regexPhone = /^[0-9]{8}$/
        console.log('data', values.email)
        if (!values.name) {
            setFormErrors({ ...formErrors, name: 'Username is required!' })
            isValid = false
        } else if (!values.name) {
            setFormErrors({ ...formErrors, name: 'Username is not validate' })
            isValid = false
        }
        if (!values.phone) {
            setFormErrors({
                ...formErrors,
                phone: 'Утасны дугаараа оруулна уу!',
            })
            isValid = false
        } else if (!regexPhone.test(values.phone)) {
            setFormErrors({
                ...formErrors,
                phone: 'Утасны дугаараа шалгана уу',
            })
            isValid = false
        }

        if (!values.email) {
            setFormErrors({ ...formErrors, email: 'Имэйл хаягаа оруулна уу!' })
            isValid = false
        } else if (!regexEmail.test(values.email)) {
            setFormErrors({ ...formErrors, email: 'Имэйл хаягаа шалгана уу' })
            isValid = false
        }

        if (!values.title) {
            setFormErrors({ ...formErrors, title: 'Гарчиг оруулна уу!' })
            isValid = false
        } else if (!values.title) {
            setFormErrors({ ...formErrors, title: 'Гарчиг оруулна уу!' })
            isValid = false
        }

        if (!values.description) {
            setFormErrors({
                ...formErrors,
                description: 'Санал хүсэлтээ оруулна уу!',
            })
            isValid = false
        } else if (!values.description) {
            setFormErrors({
                ...formErrors,
                description: 'Санал хүсэлтээ оруулна уу!',
            })
            isValid = false
        }
        return isValid
    }

    const handleChange = (e) => {
        const { name, phone, email, title, description, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    const handleSubmit = (event) => {
        event.preventDefault()
        let isValid = validate(formValues)
        setIsSubmit(true)

        const data = new FormData(event.target)

        const payload = {
            name: data.get('name'),
            title: data.get('title'),
            description: data.get('description'),
            email: data.get('email'),
            phone: data.get('phone'),
        }

        if (isValid) {
            axios
                .post('/api/contacts?page=0&size=20&searchPattern', payload)
                .then((response) => {
                    setFormErrors({})
                    setFormValues(initialValues)
                })
                .catch((err) => console.log(err))
        }
    }

    return (
        <>
            <div className="flex flex-col items-center mt-20 xs:mt-14 bg-colorbg1 cursor-default">
                <img
                    alt="dot"
                    className="absolute top-24 right-24 xs:mt-24 xs:hidden sm:hidden "
                    src={Dot}
                ></img>
                <img
                    alt="dot"
                    className="absolute top-96 left-24 xs:mt-24 xs:hidden sm:hidden "
                    src={Dot}
                ></img>
                <div className="text-center w-2/4 pt-16 text-5xl xs:w-full xs:mb-0 xs:pt-3 xs:text-4xl xs:px-10 sm:text-3xl sm:w-4/5 sm:px-6 md:w-full md:text-4xl md:px-24 lg:w-4/5 xl:w-4/5">
                    <h1 className="leading-[4rem] xs:leading-10">
                        {' '}
                        <span className="xs:hidden">
                            Бидэнтэй хамтран ажиллах
                        </span>
                        <br />
                        cанал, хүсэлтээ илгээнэ үү{' '}
                    </h1>
                </div>
                <div className="xs:pt-0">
                    <img alt="contact" className="" src={Contactimg1}></img>
                </div>
                <div className="h-4 bg-gradient-to-r from-colorbtn1 to-colorbtn2 w-full"></div>
            </div>
            <div className="flex justify-center pt-14 xs:pt-8 bg-colorbg2 cursor-default">
                <div className="w-4/5">
                    <h1 className="text-4xl font-bold text-h1red mb-2 text-center xs:text-3xl xs:w-full sm:text-3xl">
                        Бидэнтэй холбогдох
                    </h1>
                </div>
            </div>
            <div
                id="contact"
                className="flex justify-center py-20 xs:pt-8 sm:pt-12 xl:pt-10 bg-colorbg2 cursor-default"
            >
                <div className="flex w-4/5 px-10  xs:px-0 xs:w-full sm:w-full sm:px-10 md:w-full 2xl:max-w-6xl xl:space-x-1">
                    <div className="flex flex-col w-2/4 xs:hidden md:ml-10">
                        <div className="flex items-center">
                            <div className="w-1/5 sm:hidden">
                                <img
                                    alt="location"
                                    className="w-4/5"
                                    src={Location}
                                ></img>
                            </div>
                            <div className="w-4/5">
                                <h1 className=" font-medium text-2xl">Хаяг</h1>
                                <span className="text-dark opacity-60">
                                    Хоймор оффис, #707, Сүхбаатар дүүрэг,
                                    Улаанбаатар, Монгол улс, 14182
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center my-14">
                            <div className="w-1/5 sm:hidden ">
                                <img
                                    alt="email"
                                    className="w-4/5"
                                    src={Email}
                                ></img>
                            </div>
                            <div className="w-4/5">
                                <h1 className="font-medium text-2xl">И-мэйл</h1>
                                <span className="text-dark opacity-60">
                                    info@checkpoint.mn
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-1/5 sm:hidden">
                                <img
                                    alt="phone"
                                    className="w-4/5"
                                    src={Phone}
                                ></img>
                            </div>
                            <div className="w-4/5">
                                <h1 className="font-medium text-2xl">Утас</h1>
                                <span className="text-dark opacity-60">
                                    (976) 9981 3737
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-around ml-64 w-full px-20 xs:w-full xs:space-y-5 xs:px-10 xs:ml-0 sm:ml-0 sm:px-0 md:px-0 md:ml-0 md:mr-10 lg:pr-0 lg:ml-0 xl:pr-0 xl:ml-0 2xl:ml-0 2xl:pr-0">
                        <form
                            className="flex flex-col space-y-3"
                            action="/contacts"
                            method="POST"
                            onSubmit={handleSubmit}
                        >
                            <div className="flex space-x-4 w-full">
                                <div className="flex-col w-2/4">
                                    <div>
                                        <input
                                            name="name"
                                            id="name"
                                            type="text"
                                            className="shadow-sm  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                            placeholder="Овог нэр"
                                            value={formValues.name}
                                            onChange={handleChange}
                                            required
                                        ></input>
                                    </div>
                                    <div>
                                        <p className="text-red-500">
                                            {formErrors.name}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-col w-2/4">
                                    <div>
                                        <input
                                            name="phone"
                                            id="phone"
                                            type="text"
                                            className="shadow-sm  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                            placeholder="Утасны дугаар"
                                            value={formValues.phone}
                                            onChange={handleChange}
                                            required
                                        ></input>
                                    </div>
                                    <div>
                                        <p className="text-red-500">
                                            {formErrors.phone}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-col space-y-3">
                                <div className="relative">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                        </svg>
                                    </div>
                                    <div className="flex-col">
                                        <div>
                                            <input
                                                name="email"
                                                type="text"
                                                id="email"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Имэйл хаяг"
                                                value={formValues.email}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                        <div>
                                            <p className="text-red-500">
                                                {formErrors.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        name="title"
                                        type="text"
                                        id="title"
                                        className="shadow-sm  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                        placeholder="Гарчиг"
                                        value={formValues.title}
                                        onChange={handleChange}
                                        required
                                    ></input>
                                </div>
                                <p className="text-red-500">
                                    {formErrors.title}
                                </p>
                                <div>
                                    <textarea
                                        name="description"
                                        id="description"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Таны хүсэлт"
                                        value={formValues.description}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <p className="text-red-500">
                                    {formErrors.description}
                                </p>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="text-white w-56 bg-gradient-to-b from-colorbtn1 to-colorbtn2 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Илгээх
                                </button>
                            </div>
                        </form>
                        <div className="mt-3">
                            {/* <form method='POST' onSubmit={() => alert("HIHIHIHI")}> */}
                            {Object.keys(formErrors).length === 0 &&
                            isSubmit ? (
                                <div>
                                    <Stack>
                                        <Alert severity="success">
                                            <AlertTitle>
                                                Таны хүсэлт амжилттай
                                                илгээгдлээ.
                                            </AlertTitle>
                                        </Alert>
                                    </Stack>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
