import React, { useEffect ,useState } from 'react'
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom'




export default function Getinfo({model}) {
  const initialValues = { email: ""};
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) =>{
      let isValid = true;
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
      if(!values.email.trim()) {
          setFormErrors({...formErrors, email: "Та И-мейл хаягаа оруулна уу!"})
          isValid = false;
      }else if(!regexEmail.test(values.email.trim())){
          setFormErrors({...formErrors, email: "И-мейл хаягийн загвар таарахгүй байна."})
          isValid = false;
      }
      return isValid;
  }

      const handleChange = (e) => {
      const {name,email,value} = e.target;
      setFormValues({...formValues,[name]: value});
  }
  
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const handleSubmit = (event) => {
      event.preventDefault();

      let isValid = validate(formValues);
      setIsSubmit(true);
              
  
      const data = new FormData(event.target);

      const payload = {
          email: data.get('email').toString().replaceAll(' ', ''),  
          model: model
      }
      if(isValid) {
          axios
          .post('/api/infos', payload)
          .then(response => {
              setFormErrors({});
              setFormValues(initialValues);
          })
          .catch(err => console.log(err));
      }
    }

  return (
    <div className="flex justify-center -mt-24 z-50 ">
        <form className="flex flex-col w-2/5 xs:w-4/5 sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-3/5 2xl:w-2/5" method='POST' onSubmit={handleSubmit}>
          <div className="flex justify-center items-center border rounded-xl h-44 xs:h-full xs:pt-4 bg-colorbg1 ">
            <div className="flex my-3 xs:text-center xs:flex-col sm:text-center md:text-center sm:px-5">
              <div>
                <input name='email' className="input w-80 h-14 rounded-md border border-dark opacity-80 mr-5 xs:px-20 xs:w-4/5 xs:mr-0 xs:h-12 md:mb-3 sm:mx-10 sm:mb-3 sm:h-12" type="text" placeholder="И-мэйл хаяг" value={formValues.email} onChange={handleChange}></input>
                <p className="text-red-500">{formErrors.email}</p>
              </div>
              <div>
                <button type='submit' className="h-14 w-36 bg-gradient-to-b from-colorbtn1 to-colorbtn2 text-white rounded-md xs:my-3 xs:h-10 sm:h-12">Мэдээлэл авах</button>
              </div>
            </div>
          </div>
          <div>
            {Object.keys(formErrors).length === 0 && isSubmit ? (
                <div>
                        <Stack>
                            <Alert severity="success">
                            <AlertTitle>Таны хүсэлт амжилттай илгээгдлээ.</AlertTitle>
                            </Alert>
                        </Stack>
                    </div>
                ):''}
          </div>
        </form>
    </div>
  )
}
