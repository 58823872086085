import React from 'react'
import Yellowdot from '../../img/dot.svg'
import Headerimg from '../../img/mainhero.gif'
import 'aos/dist/aos.css'

export default function Hero() {
    return (
        <div
            id="home"
            className="flex justify-center bg-colorbg1 pt-32 pb-12 cursor-auto xs:pt-0 xs:pb-14 sm:pt-0 sm:pb-10 md:px-10 lg:px-10 md:pt-4 md:w-full  "
        >
            <div className="flex items-center w-4/5 h-[40rem] xs:w-full xs:flex-col-reverse xs:h-[35rem] sm:flex-col-reverse sm:w-full sm:px-5 sm:mt-28 md:w-full md:mb-0 2xl:max-w-7xl ">
                <div className="animate__animated animate__fadeInLeft w-3/6 p-14 z-10 relative xs:w-full xs:p-0 xs:text-center sm:w-full sm:p-5 md:w-full md:pl-5 md:pr-0 ">
                    <img
                        className=" -z-30  absolute -top-1 left-0 xs:hidden sm:hidden"
                        src={Yellowdot}
                        alt="yellowdot"
                    ></img>
                    <div className=" 2xl:px-10 cursor-default">
                        <h1 className=" relative text-h1red font-bold text-5xl mb-6 leading-[4rem] xs:mb-3 bg-colorbg1 xs:text-3xl  xs:px-6 sm:px-6 sm:text-4xl sm:text-center md:text-4xl ">
                            Мэдээллийн аюулгүй байдлын <br></br>{' '}
                            <span className="text-dark">Цогц үйлчилгээ</span>
                        </h1>
                        <p className="text-dark text-xl w-full bg-colorbg1 xs:px-2 xs:w-full xs:text-base sm:pl-5 sm:w-full sm:text-center ">
                            Бид танд мэдээллийн технологийнхоо үр ашгийг
                            дээшлүүлэх, мэдээллийн аюулгүй байдлаа хангахад
                            туслах бүх төрлийн үйлчилгээг санал болгож байна.
                        </p>
                    </div>
                    <img
                        className="-z-30 absolute bottom-4 right-12 xs:hidden sm:hidden"
                        src={Yellowdot}
                        alt="yellowdot"
                    ></img>
                </div>
                <div className="animate__animated animate__fadeInRight w-3/6 xs:w-3/5 sm:w-3/5 md:w-full">
                    <img className="" src={Headerimg} alt="headerimg"></img>
                </div>
            </div>
        </div>
    )
}
