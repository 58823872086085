import React from 'react'
import Otherservice from '../../components/otherservice'
import Serviceinfo from '../../pages/risk-assessment/serviceinfo'
import News from '../../pages/home/news'
import Getinfo from '../../components/getinfo'

export default function index() {
  return (
    <>
      <Serviceinfo />
      <Getinfo model={"risk"}/>
      <Otherservice />
      <News/>
    </>

  )
}
