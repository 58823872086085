import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Line from '../../img/serviceinfo/line.svg'
import img1 from '../../img/otherservice/sec2.png'
import img2 from '../../img/otherservice/sec1.png'
import Yellowdot from '../../img/dot.svg'

export default function OtherService() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <div className="flex justify-center items-center h-96 mt-20 xs:h-52 bg-colorbg1 cursor-default">
                <img
                    alt="pic"
                    className="-z-30  absolute top-24 right-20 bottom-0 xs:hidden sm:hidden"
                    src={Yellowdot}
                ></img>
                <img
                    alt="pic"
                    className="-z-30  absolute top-80 left-24 bottom-0 xs:hidden sm:hidden"
                    src={Yellowdot}
                ></img>
                <div className="w-4/5 flex justify-center text-center">
                    <div className="pl-10 text-center">
                        <h1 className="text-5xl leading-tight w-4/5 xs:text-4xl">
                            Бусад <span className="text-h1red">Үйлчилгээ </span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className=" h-4 bg-gradient-to-r from-colorbtn1 to-colorbtn2 w-full"></div>

            <div className="flex justify-center mt-14 cursor-default">
                <div className="flex-col items-center">
                    <h1 className="text-4xl  text-h1red mb-2 xs:text-3xl">
                        Бусад Үйлчилгээ
                    </h1>
                    <img alt="pic" className="hidden" src={Line}></img>
                </div>
            </div>
            <div className="flex flex-col justify-center pb-44 items-center mt-14 xs:mt-5 sm:mt-10 cursor-default">
                <div className="flex w-4/5  2xl:max-w-9xl  xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
                    <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32">
                        <img alt="pic" className="w-3/5" src={img1}></img>
                    </div>
                    <div className=" w-2/4 xs:w-full sm:w-full">
                        <h1 className="text-3xl text-h1red xs:text-2xl">
                            МАБ-ын бодлого журам боловсруулах
                        </h1>
                        <div className="mt-4">
                            <h2 className="text-xl text-h1red">
                                Developing IT security policies and procedures :
                            </h2>
                            <p className="text-justify">
                                МАБ-аа хангах үйл ажиллагааны эхний алхам нь
                                МАБ-ын бодлого, дүрэм журмаа боловсруулж,
                                хэрэгжүүлэх байдаг. Бодит амьдрал дээр
                                байгууллагын хүний нөөцийн чадамжаас шалтгаалан
                                бодлого, дүрэм журмаа зөв зохистой боловсруулж
                                чадахгүй байх тохиолдол байдаг.<br></br>{' '}
                                <br></br>
                                Бид олон улсын стандартууд дээр тулгуурлан танай
                                байгууллагын онцлогт тохирсон МАБ-н бодлого,
                                дүрэм журмыг боловсруулан өгч, хамтран ажиллах
                                боломжтой.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex w-4/5 2xl:max-w-9xl py-20 xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
                    <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32">
                        <img alt="pic" className="w-4/5" src={img2}></img>
                    </div>
                    <div className=" w-2/4   2xl:max-w-9xl xs:w-full sm:w-full">
                        <h1 className="text-3xl text-h1red xs:text-2xl">
                            МТ-ийн дотоод аудитыг ацаглах үйлчилгээ
                        </h1>
                        <div className="mt-4">
                            <h2 className="text-xl text-h1red">
                                Outsourcing IT internal audit function :
                            </h2>
                            <p className="text-justify">
                                Outsourcing IT internal audit function
                                :Мэдээллийн технологи ашигладаг аливаа
                                байгууллага нь өөрийн цахим орчны аюулгүй
                                байдлаа тогтмол шалгадаг дотоод аудитортай байх
                                нь зохимжтой байдаг. Төсөв мөнгөний хүрэлцээнээс
                                болж ийм үүрэг бүхий ажлын байр бий болгох
                                боломжгүй тохиолдол бий.<br></br>
                                <br></br>
                                Ийм байгууллагууд мэдээллийн технологийн дотоод
                                аудитын үйл ажиллагаагаа Чек Пойнт ХХК-д ацаглах
                                нь төсөв зардлаа хэмнэх өндөр ач холбогдолтой.
                                Бид танай байгууллагын мэдээллийн нууцлалыг
                                чандлан хадгалж ажиллах болно.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="flex w-4/5 2xl:max-w-9xl xs:flex-col xs:w-full xs:px-3 xs:space-y-10 sm:flex-col sm:w-full sm:px-3 sm:space-y-10 md:w-full md:px-10 md:space-x-5 lg:space-x-5">
           <div className="flex items-center justify-center w-2/4 xs:w-full sm:w-4/5 sm:pl-32"><img src={img3}></img></div>
           <div className=" w-2/4 xs:w-full sm:w-full">
             <h1 className="text-3xl text-h1red xs:text-2xl">Халдлагын шинжилгээ</h1>
             <div className="mt-4">
               <h2 className="text-xl text-h1red">Penetration test :</h2>
               <p className="text-justify">Application Development Project Teller System or known as Web Applications Branch for Bank BTN.
                Project is to replace the existing application at Bank BTN is running under the Windows platform or desktop applications into Web- ion at Bank BTN is running under the Windows platform or desktop applications into Web- ion at Bank BTN is running under the Windows platform or desktop applications into Web-based applications applications.
              </p>
             </div>
           </div>
        </div> */}
            </div>
        </>
    )
}
