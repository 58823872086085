import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Chero from '../../img/coursepage/hero.svg'
import Logo1 from '../../img/coursepage/logo1.svg'
import Logo2 from '../../img/coursepage/logo2.svg'
import Logo3 from '../../img/coursepage/logo3.svg'
import Logo4 from '../../img/coursepage/logo4.svg'
import Logo5 from '../../img/coursepage/logo5.svg'
import Line from '../../img/serviceinfo/line.svg'
import Redround from '../../img/serviceinfo/redround.svg'

export default function Course() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <div className="course-hero cursor-default bg-colorbg1 flex flex-col items-center justify-center text-4xl text-white xs:h-full xs:pt-20 sm:pt-24 md:pt-24">
                <img className="w-full " src={Chero} alt="hero"></img>
                <div className="absolute text-center pb-52 xs:text-xl  xs:pt-36 xs:px-2 sm:text-3xl sm:pt-20 md:text-3xl lg:pt-24 ">
                    <span className="font-bold">
                        Мэдээллийн аюулгүй байдлын
                    </span>
                    <br />
                    <span className="font-normal">
                        мэдлэг олгох ажилтнууд болон хэрэглэгчдэд
                    </span>
                    <br />
                    <span className="font-normal">чиглэсэн сургалтууд </span>
                </div>
            </div>
            <div className="flex justify-center pt-20 xs:pt-0 sm:pt-0 md:pt-0 bg-colorbg1 cursor-default">
                <div className="flex w-4/5 2xl:max-w-9xl pt-14 xs:space-x-7 sm:space-x-12 md:space-x-20 ">
                    <div className="flex justify-center w-1/4 ">
                        <img
                            className=" animate-bounce"
                            src={Logo1}
                            alt="lanlogo"
                        ></img>
                    </div>
                    <div className="flex justify-center w-1/4 ">
                        <img src={Logo2} alt="lanlogo"></img>
                    </div>
                    <div className="flex justify-center w-1/4 ">
                        <img
                            className=" animate-bounce"
                            src={Logo3}
                            alt="lanlogo"
                        ></img>
                    </div>
                    <div className="flex justify-center w-1/4 ">
                        <img src={Logo4} alt="lanlogo"></img>
                    </div>
                    <div className="flex justify-center w-1/4 ">
                        <img
                            className=" animate-bounce"
                            src={Logo5}
                            alt="lanlogo"
                        ></img>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center py-14 space-y-5 bg-colorbg1 cursor-default ">
                <div className="w-4/5 2xl:max-w-7xl">
                    <h1 className="text-4xl font-bold text-h1red pb-2 xs:text-3xl xs:mb-0 xs:text-center">
                        Сургалт
                    </h1>
                    <img className="hidden" src={Line} alt="line"></img>
                </div>
                <div className="w-4/5  2xl:max-w-7xl text-justify xs:text-base xs:w-full xs:px-5">
                    <p className="">
                        Технологи, Хүн болон Процесс 3 хамтдаа байж мэдээллийн
                        аюулгүй байдлын төгс хамгаалалт болдог. МАБ нь нэг
                        хэрэгжүүлээд орхидог зүйл биш юм. ISO удирдлагын
                        тогтолцоонуудын гол онцлог өөрсдийгөө байнга хянаж,
                        сайжруулж байдаг процесс болох юм. Төгс хамгаалалтын
                        үндсэн бүрдэл хэсэг буюу “Хүн” хэсгийг тогтмол сургалт,
                        мэдлэг олгох үйл ажиллагааг хэрэгжүүлэх шаардлага
                        тулгарч байдаг.
                    </p>
                    <br />
                </div>
            </div>

            <div className="flex flex-col justify-center items-center pt-12 pb-36 bg-colorbg2 cursor-default">
                <div className="w-4/5 xs:w-full 2xl:max-w-7xl">
                    <h1 className="text-4xl font-bold text-h1red mb-2 xs:text-3xl xs:text-center ">
                        МАБ-ын мэдлэг олгох сургалтын төрөл{' '}
                    </h1>
                    <img className="xs:hidden sm:hidden" src={Line}></img>
                </div>
                <div className="flex text-center w-4/5 my-12 xs:flex-col xs:space-y-8 xs:w-full xs:items-center sm:w-full sm:px-5 md:w-full md:px-10 2xl:max-w-7xl">
                    <div className="w-1/3 xs:w-full">
                        <div className="flex justify-center pb-6">
                            <img src={Redround}></img>
                        </div>
                        <div className="space-y-3">
                            <h2 className="text-dark font-bold text-2xl">
                                Байгууллага
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 sm:px-0">
                                Нийт ажилтны болон албан тушаалын түвшнээс
                                хамаарсан хэсэгчилсэн сургалт
                            </p>
                        </div>
                    </div>
                    <div className="w-1/3 xs:w-full">
                        <div className="flex justify-center pb-6">
                            <img src={Redround}></img>
                        </div>
                        <div className="space-y-3">
                            <h2 className="text-dark font-bold text-2xl">
                                Ажилтан
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 sm:px-0 ">
                                Байгууллагын ажиллагсдад МАБ-ын мэдлэг олгох шат
                                шатны агуулга бүхий материал
                            </p>
                        </div>
                    </div>

                    <div className="w-1/3 xs:w-full">
                        <div className="flex justify-center pb-6 ">
                            <img src={Redround}></img>
                        </div>
                        <div className="space-y-3">
                            <h2 className="text-dark font-bold text-2xl">
                                Хэрэглэгч
                            </h2>
                            <p className="opacity-60 px-12 xs:px-0 sm:px-0 ">
                                Байгууллагын эцсийн хэрэглэгчдийн МАБ-ын мэдлэг
                                чадварыг дээшлүүлэх материал
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
