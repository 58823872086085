import Aos from 'aos'
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'
import Roundsvg from '../../img/aboutsection/Group.svg'
import Img1 from '../../img/aboutsection/img1.svg'
import Img2 from '../../img/aboutsection/img2.svg'
import Img3 from '../../img/aboutsection/img3.svg'
import Img4 from '../../img/aboutsection/img4.svg'
import Img5 from '../../img/aboutsection/img5.svg'
import Img6 from '../../img/aboutsection/img6.svg'

export default function About() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const styles = [
        'font-normal xs:font-bold sm:font-bold md:font-bold uppercase',
    ]
    //   consty styles1 = []

    return (
        <div
            id="about"
            data-aos="fade-up"
            className="flex justify-center bg-colorbg2 w-full cursor-default xs:pb-6  sm:mb-44 md:mb-0 "
        >
            <img
                alt="pic"
                className="absolute z-10 left-0 mt-14 xs:hidden sm:w-36 sm:"
                src={Roundsvg}
            ></img>
            <div className="flex w-4/5 px-14 my-24 xs:flex-col xs:w-full xs:my-1 xs:px-0 sm:flex-col sm:w-full sm:px-0 sm:my-0 sm:text-center md:flex-col md:w-full md:px-16 md:my-0 md:text-center lg:w-full lg:px-10 2xl:max-w-7xl">
                <div className="flex flex-col justify-center w-full  xs:w-full xs:px-3 sm:w-full sm:px-7 md:w-full">
                    <div className="xs:text-center">
                        <h1 className="text-4xl font-bold leading-10 py-5 text-h1red xs:text-3xl">
                            Чек Пойнт{' '}
                            <span className="text-black text-2xl">
                                гэж хэн бэ?
                            </span>
                        </h1>
                        <p className="leading-7 text-dark text-base text-justify">
                            Чек Пойнт ХХК нь 2021 онд үүсгэн байгуулагдсан
                            мэдээллийн технологийн аудитын үйлчилгээ үзүүлдэг
                            компани юм. Бид цаашдаа мэдээллийн технологи,
                            мэдээллийн аюулгүй байдлын аудитын Монгол улсын
                            тэргүүлэх компани болох зорилго тавин, үйл
                            ажиллагаандаа дараах зарчмуудыг баримтлан ажиллаж
                            байна.
                        </p>
                    </div>
                    <div className="flex justify-center w-full xs:mt-10 sm:w-full md:mt-0">
                        <div className="flex flex-wrap justify-between space-y-20 pb-10 xs:justify-center xs:px-0 xs:space-y-5 xs:space-x-3 sm:px-0 sm:space-y-20 md:px-0 md:w-full ">
                            <div className="flex flex-col mt-20 space-y-3 items-start w-1/3 hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg  p-1 px-10 xs:px-5  xs:w-4/5 xs:mt-0 sm:mb-0 sm:px-1 md:mb-0  ">
                                <img
                                    alt="pic"
                                    className="left-0"
                                    src={Img1}
                                ></img>
                                <p className={styles}>Бие даасан, хараат бус</p>
                                <p className="">
                                    Аудит хийлгэж буй захиалагчаас бусад
                                    этгээдээс хараат бус байх
                                </p>
                            </div>
                            <div className="flex flex-col mt-5 space-y-3 items-start w-1/3 hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg    p-1 px-10 xs:px-5 xs:w-4/5 xs:mt-0 sm:px-1 ">
                                <img
                                    alt="pic"
                                    className="h-10"
                                    src={Img2}
                                ></img>
                                <p className={styles}>Шударга, ёс зүйтэй</p>
                                <p className="text-cardtextcolor">
                                    Холбогдох хууль тогтоомж, шаардлагын дагуу
                                    шударга ажиллах{' '}
                                </p>
                            </div>
                            <div className="flex flex-col mt-5 space-y-3 items-start w-1/3 hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg  p-1 px-10 xs:px-5 xs:w-4/5 xs:mt-0 sm:px-1">
                                <img
                                    alt="pic"
                                    className="h-10"
                                    src={Img3}
                                ></img>
                                <p className={styles}>Мэдээллийн нууцлал</p>
                                <p className="text-cardtextcolor">
                                    Захиалагчийн болон аудитын мэдээллийг
                                    нууцалж, хамгаалах
                                </p>
                            </div>
                            <div className="flex flex-col mt-5 space-y-3 items-start w-1/3  hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg  p-1 px-10 xs:px-5 xs:w-4/5 xs:mt-0 sm:px-1 ">
                                <img
                                    alt="pic"
                                    className="h-10"
                                    src={Img4}
                                ></img>
                                <p className={styles}>Мэргэжлийн ур чадвар</p>
                                <p className="text-cardtextcolor">
                                    Аудитын явцад мэргэжлийн, үл итгэх байр
                                    сууринаас хандах
                                </p>
                            </div>
                            <div className="flex flex-col mt-5 space-y-3 items-start w-1/3  hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg  p-1 px-10 xs:px-5 xs:w-4/5 xs:mt-0 sm:px-1 ">
                                <img
                                    alt="pic"
                                    className="h-10"
                                    src={Img5}
                                ></img>
                                <p className={styles}>Нотолгоонд суурилсан</p>
                                <p className="text-cardtextcolor">
                                    Баримжтуулж болохуйц нотлох баримтад
                                    тулгаарласан байх
                                </p>
                            </div>
                            <div className="flex flex-col mt-5 space-y-3 items-start w-1/3  hover:bg-colorbg1 hover:scale-110 text-cardtextcolor hover:shadow-lg transition-all duration-300 hover:rounded-lg  p-1 px-10 xs:px-5 xs:w-4/5 xs:mt-0  sm:px-1">
                                <img
                                    alt="pic"
                                    className="h-10"
                                    src={Img6}
                                ></img>
                                <p className={styles}>Үнэн, бодит дүгнэлт</p>
                                <p className="text-cardtextcolor">
                                    Дүгнэлтэд үнэн зөв, бүрэн мэдээллийг
                                    тусгадаг байх
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
