import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../img/logo.svg'
import { Link } from 'react-router-dom'

export default function Footer() {
    const navigate = useNavigate()

    const handleUrl = () => {
        navigate('/contact')
        // history.push('/contact')
        // history.go(0)
    }

    return (
        <>
            <div className="flex justify-center bg-colorbg2  xs:mt-0  sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 cursor-default">
                <div className="flex flex-col w-3/5 xs:w-4/5 xs:items-center sm:w-4/5 md:w-4/5 2xl:max-w-6xl">
                    <div className="flex pt-8 pb-8 xs:flex-col-reverse xs:pt-10 sm:flex-col-reverse">
                        <div className="w-1/3 xs:mt-10 justify-items-center xs:w-full xs:px-5 sm:w-full sm:px-16">
                            <img alt="logo" className="mb-5" src={Logo}></img>
                            <span className="text-gray opacity-60 hidden">
                                © 2021-2022 -Чек Пойнт ХХК <br /> Бүх эрх
                                хуулиар хамгаалагдсан
                            </span>
                            <br />

                            {/* <div className="flex justify-center items-center h-14 w-52 mt-5 text-center text-base  bg-gradient-to-b from-colorbtn1 to-colorbtn2 text-white rounded-md xs:my-3 hover:scale-105 duration-300 transition-all"> */}
                            <Link
                                to="/contact"
                                // onClick={() => navigate('/analysis')}
                                className="flex justify-center items-center h-14 w-52 mt-5 text-center text-base  bg-gradient-to-b from-colorbtn1 to-colorbtn2 text-white rounded-md xs:my-3 hover:scale-105 duration-300 transition-all"
                            >
                                Хамтран ажиллах <br />
                                санал хүсэлт илгээх
                            </Link>
                            {/* </div> */}
                        </div>
                        <div className="flex flex-col w-2/3 xs:w-full sm:w-full">
                            <div className="flex text-h1red">
                                <div className="w-2/4 pl-16 pt-3 xs:hidden">
                                    <h1 className="text-2xl font-bold">
                                        Үйлчилгээ
                                    </h1>
                                </div>
                                <div className="w-2/4 pl-16 pt-3 xs:w-full xs:px-5">
                                    <h1 className="text-2xl font-bold pr-34">
                                        Холбоо барих
                                    </h1>
                                </div>
                            </div>
                            <div className="flex mt-3 xs:flex-col xs:w-full ">
                                <div className="w-2/4 pl-16 pt-4 xs:hidden ">
                                    <ul className="text-gray opacity-60">
                                        <li className="hover:text-h1red transition-all duration-300">
                                            <Link
                                                to="/audit"
                                                // onClick={() => navigate('/analysis')}
                                            >
                                                Аудитын үйлчилгээ
                                            </Link>
                                        </li>
                                        <li className="hover:text-h1red transition-all duration-300">
                                            <Link
                                                to="/risk"
                                                // onClick={() => navigate('/analysis')}
                                            >
                                                Эрсдэлийн үнэлгээ
                                            </Link>
                                        </li>
                                        <li className="hover:text-h1red transition-all duration-300">
                                            <Link
                                                to="/analysis"
                                                // onClick={() => navigate('/analysis')}
                                            >
                                                Шалгалт, шинжилгээ
                                            </Link>
                                        </li>
                                        <li className="hover:text-h1red transition-all duration-300">
                                            <Link
                                                to="/course"
                                                // onClick={() => navigate('/analysis')}
                                            >
                                                Сургалт
                                            </Link>
                                        </li>
                                        <li className="hover:text-h1red transition-all duration-300">
                                            <Link
                                                to="/otherservice"
                                                // onClick={() => navigate('/analysis')}
                                            >
                                                Бусад үйлчилгээ
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-2/4 pl-16 pt-4 xs:w-full xs:px-5">
                                    <ul className="text-gray opacity-60">
                                        <li>Хаяг : Хоймор оффис, #707, </li>
                                        <li>Сүхбаатар дүүрэг, Улаанбаатар,</li>
                                        <li>Монгол улс, 14182 </li>
                                        <li>Утас : +976 9981 3737</li>
                                        <li>И-мэйл : info@checkpoint.mn</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex h-16  items-center justify-center text-center bg-h1red cursor-default">
                <span className="text-white">
                    © 2021-2022 -Чек Пойнт ХХК Бүх эрх хуулиар хамгаалагдсан
                </span>
            </div>
        </>
    )
}
