import React from 'react'
import { useLocation } from 'react-router-dom'
import Line from '../img/serviceinfo/line.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Risk from '../img/risk.gif'
import Other from '../img/other.gif'
import Analysis from '../img/analysis.gif'
import Course from '../img/course.gif'
import { Link } from 'react-router-dom'

export default function OtherService() {

    const { pathname } = useLocation()

    return (
        <>
            <div className="flex flex-col justify-center items-center pt-44 px-4 bg-colorbg1 -mt-24 cursor-default">
                <div className="w-4/5 2xl:max-w-8xl">
                    <h1 className="text-4xl font-bold text-h1red pb-2 xs:text-3xl xs:text-center xs:pb-8 sm:text-3xl md:text-3xl">
                        Бусад үйлчилгээ
                    </h1>
                    <img className="xs:hidden" src={Line} alt="line"></img>
                </div>
            </div>
            <div className="flex justify-center px-4 md:px-24 md:w-full bg-colorbg1 cursor-default">
                <div className="flex flex-wrap justify-between w-4/5 xl:max-w-9xl  2xl:max-w-8xl py-10 xs:py-0 xs:space-y-5 sm:space-y-5 md:space-y-5 lg:space-y-5 xl:space-y-5 2xl:space-y-5 3xl:space-y-5 ">
                    {pathname !== '/analysis' && (
                        <div className="flex-col w-500 bg-white rounded-lg p-6 mt-5 shadow-lg xs:flex-col-reverse md:w-500  2xl:w-550 ">
                            <div className="flex xs:flex-col-reverse">
                                <div className="w-3/5 xs:w-full xs:text-center ">
                                    <h3 className="text-h1red text-xl font-bold pb-2">
                                        Шалгалт, шинжилгээ
                                    </h3>
                                    <p className="text-dark my-2 text-base leading-7 opacity-60 text-justify">
                                        Эмзэг байдлийн (Vulnerability),
                                        Нэвтрэлтийн (Penetration test),
                                        Халдлагын дараах (Forensic analysis)
                                        зэрэг шалгалт, шинжилгээ.{' '}
                                    </p>
                                </div>
                                <div className="flex justify-center w-2/5 xs:pb-0 xs:w-full">
                                    <img
                                        className="w-3/5"
                                        src={Analysis}
                                        alt="service1"
                                    ></img>
                                </div>
                            </div>
                            <div className="xs:flex xs:justify-center">
                                <Link
                                    to="/analysis"
                                    // onClick={() => navigate('/analysis')}
                                    className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                                >
                                    Дэлгэрэнгүй
                                </Link>
                            </div>
                        </div>
                    )}
                    {pathname !== '/course' && (
                        <div className="flex-col w-500 bg-white rounded-lg p-6 shadow-lg xs:flex-col-reverse 2xl:w-550 ">
                            <div className="flex xs:flex-col-reverse">
                                <div className="w-3/5 xs:w-full xs:text-center ">
                                    <h3 className="text-h1red text-xl font-bold pb-2">
                                        Сургалт
                                    </h3>
                                    <p className="text-dark my-2 text-base leading-7 opacity-60 text-justify">
                                        Мэдээллийн аюулгүй байдлын мэдлэг олгох
                                        ажилтнууд болон харилцагчдад чиглэсэн
                                        сургалтууд.{' '}
                                    </p>
                                </div>
                                <div className="flex justify-center w-2/5 xs:pb-0 xs:w-full">
                                    <img
                                        className="w-3/5"
                                        src={Course}
                                        alt="service2"
                                    ></img>
                                </div>
                            </div>
                            <div className="xs:flex xs:justify-center">
                                <Link
                                    to="/course"
                                    // onClick={() => navigate('/analysis')}
                                    className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                                >
                                    Дэлгэрэнгүй
                                </Link>
                            </div>
                        </div>
                    )}
                    {pathname !== '/otherservice' && (
                        <div className=" flex-col w-500 bg-white rounded-lg p-6 shadow-lg xs:flex-col-reverse 2xl:w-550 ">
                            <div className="flex xs:flex-col-reverse">
                                <div className="w-3/5 xs:w-full xs:text-center ">
                                    <h3 className="text-h1red text-xl font-bold pb-2">
                                        Бусад
                                    </h3>
                                    <p className="text-dark my-2 text-base leading-7 opacity-60 text-justify">
                                        МТ-ийн дотоод аудитыг ацаглах
                                        (outsource), МАБ-ын бодлого журам
                                        боловсруулах зэрэг бусад үйлчилгээ.{' '}
                                    </p>
                                </div>
                                <div className="flex justify-center w-2/5 xs:pb-0 xs:w-full">
                                    <img
                                        className="w-3/5"
                                        src={Other}
                                        alt="service3"
                                    ></img>
                                </div>
                            </div>
                            <div className="xs:flex xs:justify-center">
                                <Link
                                    to="/otherservice"
                                    // onClick={() => navigate('/analysis')}
                                    className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                                >
                                    Дэлгэрэнгүй
                                </Link>
                            </div>
                        </div>
                    )}
                    {pathname !== '/risk' && (
                        <div className=" flex-col w-500 bg-white rounded-lg p-6 shadow-lg xs:flex-col-reverse 2xl:w-550  ">
                            <div className="flex xs:flex-col-reverse">
                                <div className="w-3/5 xs:w-full xs:text-center ">
                                    <h3 className="text-h1red text-xl font-bold pb-2">
                                        Эрсдэлийн үнэлгээ
                                    </h3>
                                    <p className="text-dark my-2 text-base leading-7 opacity-60 text-justify">
                                        Байгууллагын мэдээллийн технологийн
                                        эрсдэлийн үнэлгээ.{' '}
                                    </p>{' '}
                                </div>
                                <div className="flex justify-center w-2/5 xs:pb-0 xs:w-full">
                                    <img
                                        className="w-3/5"
                                        src={Risk}
                                        alt="service4"
                                    ></img>
                                </div>
                            </div>
                            <div className="xs:flex xs:justify-center">
                                <Link
                                    to="/risk"
                                    // onClick={() => navigate('/analysis')}
                                    className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                                >
                                    Дэлгэрэнгүй
                                </Link>
                            </div>
                        </div>
                    )}
                    {pathname !== '/audit' && (
                        <div className=" flex-col w-500 bg-white rounded-lg p-6 shadow-lg xs:flex-col-reverse 2xl:w-550 ">
                            <div className="flex xs:flex-col-reverse">
                                <div className="w-3/5 xs:w-full xs:text-center ">
                                    <h3 className="text-h1red text-xl font-bold pb-2">
                                        Аудит
                                    </h3>
                                    <p className="text-dark my-2 text-base leading-7 opacity-60 text-justify">
                                        Мэдээллийн технологи болон Мэдээллийн
                                        аюулгүй байдлын аудитын үйлчилгээ.
                                    </p>{' '}
                                </div>
                                <div className="flex justify-center w-2/5 xs:pb-0 xs:w-full">
                                    <img
                                        className="w-3/5"
                                        src={Risk}
                                        alt="service4"
                                    ></img>
                                </div>
                            </div>
                            <div className="xs:flex xs:justify-center">
                                <Link
                                    to="/audit"
                                    // onClick={() => navigate('/analysis')}
                                    className="w-36 h-10 flex justify-center items-center bg-gradient-to-b from-colorbtn1 to-colorbtn2 rounded text-buttontextcolor text-lg hover:scale-105 duration-300 transition-all"
                                >
                                    Дэлгэрэнгүй
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

